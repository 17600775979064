// @ts-ignore
import 'bigint-polyfill';
import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Loader from './Loader';
import Footer from '../Components/Footer';
import NFTDASH from '../Components/NFTDash';
import Logo from '../Images/loadinglogo.png';


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';





// Custom Chakra default theme 
const colors = {
  primary: {
    900: '#050405',
    800: '#3A72F3',
    700: '#97C0FA',
    600: '#F9B208',
    500: '#FB5BB9',
    400: '#EB2A64',
    300: '#16A8FB'
  },
  secondary: {
    900: '#01040D',
    800: '#575761',
    700: '#1C1326'
  }
}

const styles = {
  global: {
    'html, body, css': {
      color: 'secondary.800',
      lineHeight: 'tall',
      fontSize: '16px',
      overflow: 'auto',
      Font: 'hacked-kerX',
      background:`#000000`, 
      boxSizing: 'border-box'
    },
    a: {
      fontSize: "20px",
      textDecoration: "none"
    }
  },
}

const breakpoints = {
  sm: '385px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1536px',
}

const theme = extendTheme({ colors, styles, breakpoints })

const projectId = '70dc64a5457ae6317457f52979348d59'
const eth = {
  chainId: 8453,
  name: 'Base',
  currency: 'ETH',
  explorerUrl: 'https://basescan.org/',
  rpcUrl: 'https://base.llamarpc.com'
}

const metadata = {
  name: 'Geeks Pets',
  description: 'Geeks Pets',
  url: 'Geeks Pets', 
  icons: [{Logo}]
}

const ethersConfig = defaultConfig({
  metadata,


})

createWeb3Modal({
  
  ethersConfig,
  chains: [eth],
  projectId,
  enableInjected: true,
  enableAnalytics: true
})

function App() {

  


  return (
   

    
    <ChakraProvider theme={theme} >
      <BrowserRouter>
        <Box px={{base: "8%", md:"12%", lg: "15%"}}>
          <Navbar/>
          <Routes>
            <Route path="/" element={<NFTDASH />}/>
          </Routes>
          <Footer/>
        </Box>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
